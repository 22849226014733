.hurish_container{

    width:100%;
    height:auto;
    
    background-image: url('./../img/fons2.jpg');
 
    background-repeat: repeat;
    
  
    
    background-size: 550px 260px;
    
}


.hurish {
 
 width:100%;
    
    background-color:  #f5f5f5f3;
    
    height: auto;
      
}
 