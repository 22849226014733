.inl{
    position: fixed; 
   width: 45px;
   left: 90%;
   bottom: 30px;
   height: 45px;
    
   z-index: 999;
   cursor: pointer;
   color:#db8f1d;
  color: rgb(242, 138, 30);
 border-radius: 3px;
 
  
 
}

 

@media (max-width: 640px) {
    .inl{
        position: fixed; 
       width: 45px;
       left: 85%;
       bottom: 30px;
       height: 45px;
    
       z-index: 1;
       cursor: pointer;
       
    }


}