.navbar_all{
  width:100%;
box-shadow: 0 5px 30px rgb(0 22 84 / 10%); 
 margin:0px;

  padding:0px;
 z-index: 99;
 position:relative;
 height:120px;
 -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
                                    background-color: #323232ae;
                                    transition: .5s;   position:fixed;                                                 
}

:target{
  scroll-margin-top: 200px;
}
.navbar_fixed{
  width:100%;
  background-color: #fff;
 
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%); 
 margin:0px;
 
  padding: 0px;
 z-index: 99;
 position:relative;
 height:70px;
  margin-top:0px;
  transition: .5s;
  position:fixed;
}



 

 
 









.nb{
 max-width: 1200px;    
 margin:0 auto; 
color:white; color:   #3d3d3d; 
padding:0px;
padding-right:15px;
padding-left:15px;
height:100%;
 

}
.menu{   /*  Обш. меню строка */
 
   width:100%;
  
   display:flex; align-items: center;
justify-content:space-around;
  text-decoration: none;
  height:100%;
  transition: all 0.1s ease;  
 
}


.menu   .dlogo1 {   /* white    */
  width:180px;
  height:47px;
  float:left;
  background: url("../img/log_4.png");
 
  background-repeat: no-repeat; /* Не повторяем */
  background-size: cover; /* Размер фона */
  background-size: 100%  95%; /* Размер фона */
  background-position: center; /* Позиция картинкипо центру */
 
}

.menu   .dlogo2 {  /*   yellow    */
  width:150px;
  height:60px;
  float:left;
  background: url("../img/log_1.png");
 
  background-repeat: no-repeat; /* Не повторяем */
  background-size: 100%  100%; /* Размер фона */
  background-position: center; /* Позиция картинкипо центру */
  
}


.menu_ul{    /*    Меню */
  height:60px;
    list-style: none;
  width:auto;
  text-decoration: none;
  margin: 0px;
  transition: all 0.1s ease;
  border-radius: 0px;
text-transform: uppercase;
  align-items: center;
  display:flex;
  flex-direction: row;
  color:#ffffff;
  font-family: 'Montserrat'; font-size: 14px;
   

  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.7;
  text-transform: none;
margin-right:-70px;


}

.menu_ul_2{    /*    Меню */
  height:60px; 
    list-style: none;
  width:auto;
  text-decoration: none;
  margin: 0px;
  transition: all 0.1s ease;
  border-radius: 0px;
text-transform: uppercase;
  align-items: center;
  display:flex;
  flex-direction: row;
  color:#3d3d3d;
  font-family: 'Montserrat'; font-size: 14px;
  margin-right:-70px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.7;
  text-transform: none;


}

  li{
  cursor: pointer;
  padding-left:15px;
  padding-right:15px;
 
  height:100%;  
  padding-top:18px;
  font-weight: 500;
  
  text-transform: none;
  
}

 


  li span{
  width:auto;
   
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
 



  li span .spico{
  font-size: 12px;
  margin-left:5px;
  background-image: url('../img/dw.png');
  background-size: contain;
  width: 12px;
  height: 12px;
 }
 li span .spico_2{
  font-size: 12px;
  margin-left:5px;
  background-image: url('../img/down.png');
  background-size: contain;
  width: 12px;
  height: 12px;
 }

 li:hover .spico{
  background-image: url('../img/up1.png');
   transition: 0.3s;
}
  

    img.korzina{
      margin-left:40px;
   height:40px;
   width:45px;
   margin-top:-5px;
   padding:6px 6px 6px 6px;
   
  
}

  img.korzina_2{
 margin-left:40px;
   height:40px;
   width:45px;
   margin-top:-5px;
   padding:6px 6px 6px 6px;
}

  li ul{
   position: absolute;
    list-style: none;
    font-weight: 200;
     font-family: Montserrat; 
    width:auto;
   text-transform:none;
    margin: 0px;
    border-radius: 0px;
    margin-top:17px;
    color:   #3d3d3d; 
    background-color:white;
    text-align: left;
    box-shadow: 0px 5px 10px -3px rgb(61, 55, 54);
 padding:0px; 
 text-decoration: none; 
 z-index: 5;
  opacity: 0;
 transform: scaleY(0);
 transform-origin: 0 0;
 transition: .5s ease-in-out;
}


  li:hover>ul{
opacity: 1;
transform: scaleY(1);
}

  li ul li{
padding:0px;
font-weight: 500;
font-size: 12px;
margin:0px;
width:200px;
height:40px;    
padding-top:3px;
}


.underline-one {
	 
    position: relative;
    cursor: pointer;
    text-decoration: none; /* Убираем подчеркивание */
}
.underline-one:after {
	content: "";
    display: block;
    position: absolute;
    right: 0;
    bottom: -3px;
    width: 0;
    height: 1px; /* Высота линии */
    background-color: #3d3d3d; /* Цвет подчеркивания при исчезании линии*/
    transition: width 0.5s; /* Время эффекта */
}

.underline-one:hover:after {
	content: "";
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 1px; /* Высота линии */
    background-color: #3d3d3d;  /* Цвет подчеркивания при появлении линии*/
    transition: width 0.5s;  /* Время эффекта */
}




 li ul li:hover{    
  transition: 0.2s;
}

 li ul li:hover a{
  font-weight: 500;
  
  transition: 0.2s;
  cursor: pointer;
  padding-left:5px;
}
  li ul li a{
  margin-left:5px;
  text-decoration: none;
  padding-left:0px;
 color:  #3d3d3d;   
  transition: 0.2s;
  font-size: 14px;
  font-weight: 400;
  
}
 
.liline{
  width:90%;
  margin:0 auto;
  height:100%;
  border-bottom:1px solid rgb(204, 197, 191);
  padding:7px 7px 7px 0px;
  font-weight: 100;
  
}

.liline_no{
  width:90%;
  margin:0 auto;
  height:100%;
  border:none;
  padding:7px 7px 7px 0px;
  font-weight: 100;
  
}

.liline_no a img{
  margin-right:20px;
 
  width:26px;
  height:100%;
}



.send{
  font-family: 'Montserrat';
  font-size: 13px;
  color:white;
}
.send img{
  width:35;
  margin-left:5px;
  height:25px;
}
 

.send2{
  font-family: 'Montserrat';
  font-size: 13px;
}

.send2 img{
  width:35;
  margin-left:5px;
  height:25px;
}
sup{
  font-size: 8px;
}
  .menu_box{
    display:none;
    }

  input.inhide{
    display:none;
  }


  
div.d1 {
   padding:5px;
  padding-left:25px;}
form {
  position: relative;
  width: 250px;
  margin: 0 auto;
  
  
}
.d1 {
  background: none;
 
height:100%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}
.d1 input {
  width: 100%;
  height: 30px;
  padding-left: 10px;
  border: none;
  border-radius: 0px;
  outline: none;
  background: #ffffff;
  color: #c0bebe;
  font-size: 14px; border:1px #3d3d3d solid;
}
.d1 button {
  position: absolute; 
  top: 0px;
  right: -2px;
  
  border: none;
 
  background:  #3d3d3d;
  border-radius:   0 3px 3px 0 ;
  cursor: pointer;
  clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0 100%);
  border: 0px none;
  width:41px;
  height: 30px;
}

.iconssearch{
  color:rgb(255, 255, 255);
  font-size: 16px;
  margin-left:4px;
 
  }
 

 
/*********************                          search fr2                    ********************/



  .fr2 {
    padding:5px;
  padding-left:30px;
  height:60px;
 
  display: flex;
  align-items: center;
  }

   .fr2  form {
   position: relative;
   width: 80%;
   margin: 0;
    
   
 }

 .fr2 input {
   width: 100%;
   height: 30px;
   padding-left: 10px;
   border: none;
   border-radius: 0px;
   outline: none;
   background: #ffffff13;
   color: #c0bebe;
   font-size: 14px; border:1px #3d3d3d solid;
 }
 .fr2 button {
   position: absolute; 
   top: 0px;
   right: -2px;
   
   border: none;
  
   background:  #3d3d3d;
   border-radius:   0 3px 3px 0 ;
   cursor: pointer;
   clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0 100%);
   border: 0px none;
   width:41px;
   height: 30px;
 }
 
 .fr2 .iconssearch{
   color:rgb(255, 255, 255);
   font-size: 16px;
   margin-left:4px;
  
   }
  


























.iconsmenu{
 font-size: 14px;
 margin-right:30px;
 margin-top:-4px;
 margin-left:5px;

  color:grey; 
}

.iconsmenu2{
  font-size: 14px;
  margin-right:30px;
  margin-top:-4px;
  margin-left:10px;
 
  color: white;
 }

 

 .na{
  margin:0px; 
 padding:0px;
  
  font-size: 13px;
 padding:5px;
 display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
 

  
}



ul.userul{
  list-style: none; margin-left:40px;
    cursor: pointer;
   height:100%;
    margin-top: 17px;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 padding:0px;
  width:60px;  height:60px;
 
}

ul.userul li{
  height:100%;
 
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

ul.userul li{
  height:100%;
   
  width:100%;

}


ul.userul li.flex:hover>ul.userli{
display: block;
}

ul.userli{
  list-style: none;
  background-color: white;
 height:auto;
  width:auto;
  margin-left:-160px;
  margin-top:205px;
  position:absolute;
  padding:10px;
  display: flex;
  flex-direction: column;
  border:2px solid #3d3d3d;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.2);
   display: none;
}
 

ul.userli::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: 72px;
  
  margin-top:-16px;
  border-width:7px;
  border-style: solid;
  border-color: transparent transparent #3d3d3d transparent;
}



ul.userli li{
  
 padding:3px;
  display: flex;
  flex-direction: row;  
  width:100%;
  justify-content: right;
}
ul.userli li img{
  margin-left:7px;
}
ul.userli li a{
   color:#8a8989;
}

ul.userli li a:hover{
  color:#3d3d3d;
}

 
ul.userli li span{
  font-size: 13px;
}


 

.sp_item{
  padding-left:40px;
  font-size: 12px;
}
.sp_item span{
  color:#fff;
}

.icons{
   color:#fff;
   
  
 
   
  
  
cursor: pointer;
 
}

 


















  
  /**********************************************  Menu Burger      *******************************************************************/

  @media (max-width: 990px) {
      




    .navbar_all{
      width:100%;
     
     margin:0px;
       /* box-shadow: 1px 3px 10px -3px rgb(14,18,31);*/
      padding:0px;
     z-index: 99999;
     position:relative;
     height:70px;
      margin-top:0px;
      box-shadow: none;
      transition: .3 ease-in-out;
      position:fixed;
      border-bottom:1px solid rgba(213, 221, 228, 0.429);
    
    }
    
    
    .navbar_fixed{
      width:100%;
      
     margin:0px;
        box-shadow: none;
      padding:0px;
     z-index: 99999; 
     position:relative;
     height:70px;
      margin-top:0px;
      transition: .3 ease-in-out;
      position:fixed;
      border-bottom:1px solid rgba(213, 221, 228, 0.429); 
    }
    

    .menu{   /*  Обш. меню строка */
 
      width:100%;
    
      display:flex; align-items:center;
   justify-content:space-between;
     text-decoration: none;
     height:100%;
     transition: all 0.1s ease;  
    
   }


    .menu   .dlogo1 {   /* white    */
      width:160px;
      height:40px;
      float:left;
      background: url("../img/log_4.png");
     
      background-repeat: no-repeat; /* Не повторяем */
      background-size: cover; /* Размер фона */
      background-size: 90%  80%; /* Размер фона */
      background-position: center; /* Позиция картинкипо центру */
      
    }
    
    .menu   .dlogo2 {  /*   yellow    */
      width:150px;
      height:60px;
      float:left;
      background: url("../img/log_1.png");
     
      background-repeat: no-repeat; /* Не повторяем */
      background-size: 95%  100%; /* Размер фона */
      background-position: center; /* Позиция картинкипо центру */
     
    }
    
    


    img.korzina{
      border:2px #ffffff solid;
       height:38px;
       width:40px;
       margin-top:0px;
       padding:5px 5px 5px 5px;
     
       
    }
    
      img.korzina_2{
      border:2px #666666 solid;
       height:38px;
       width:40px;
       margin-top:0px;
       padding:5px 5px 5px 5px;
      
    }



    .nav-link {
      display: flex;
 
    justify-content: center;
    align-items: center;
    margin-right:50px;
  }



  .send2{
    font-family: 'Montserrat';
    font-size: 12px;
    display: none;
  }
  
  .send2 img{
    width:30;
    margin-left:5px;
    height:20px;  display: none;
  }
  sup{
    font-size: 8px;
  }


  .send{
    font-family: 'Montserrat';
    font-size: 12px;  display: none;
  }
  
  .send img{
    width:30;  display: none;
    margin-left:5px;
    height:20px;
  }
  




    .menu_ul_2{
      display: none;
    }




    .menu_ul{
      display: none;
    }
    .d1{
      display: none;
    }

    .dlogo{
   
      width:100%;
      display:flex;
   justify-content:flex-start;
   padding-left:50px;
   }


.navbar-toggler{
display:none;

}

 



 

.menu_box {                    /*    ---------------         MENU     -------------------   */
  display: flex;
  position: fixed; 
 
  top: 0;
 
 margin-top:70px;
  width: 100%;
  height: 100%;
  visibility: hidden;
  padding: 10px;
  padding-left:0px;
 top:0px;
  list-style: none;
  
  transition-duration: .25s;
 
  z-index: 101;
  overflow:hidden;
  
 

  padding:0px;
 z-index: 99;
 position:relative;
 height:150px;
 -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
                                    background-color: #323232ae;
                                    transition: .5s;   position:fixed;   


justify-content:flex-end;
align-items: center;

 



}
 
 

#menu_toggle {
  opacity: 0;
}

#menu_toggle:checked~.menu_btn>span {

  transform: rotate(45deg);
}

#menu_toggle:checked~.menu_btn>span::before {
  top: 0;
  transform: rotate(0);
}

#menu_toggle:checked~.menu_btn>span::after {
  top: 0;
  transform: rotate(90deg);
}



#menu_toggle:checked~.menu_box {
  visibility: visible;
  left: 0;
 
  
}



#menu_toggle:checked~.menu_btn {
  width: 70px;
  height: 70px;
  background: none;
 
}

html body div.hamburger-menu label.menu_btn {
  display: flex;
  align-items: center;
  position: fixed;
   top: 0px;
right: 0px; 
  width: 70px;
  height: 70px;
  cursor: pointer;
  z-index: 99990;
  padding-left: 20px;
  color:#ffffff; color: #3d3d3d;
   
}

.menu_btn>span,
.menu_btn>span::before,
.menu_btn>span::after {
  display: block;
  position: absolute;
  width: 25px;
  height: 4px;
  background-color:   #db8f1d;
  color:#db8f1d;
  transition-duration: .25s;
  
}

.menu_btn>span::before {
  content: '';
  top: -8px;
}

.menu_btn>span::after {
  content: '';
  top: 8px;
}


 

.menu_box_send{
  font-family: 'Montserrat';
  font-size: 12px;
  color:white;
 
 width:60%;
}


.menu_box_send table  {
  width:auto;
  text-align: left;
  
}


.menu_box_send table tr td{
   width:auto;
   text-align: left;
 
}


.menu_box_send img{
  width:35px;
  margin-left:0px;
  height:25px;
 
 
}
 




.menu_box .ul_li{
  list-style: none;
  display: flex;
  justify-content: center;
  align-items:flex-start;
  flex-direction: column;
  margin:0px;
 height:100%;
  padding:10px;
 
 width:130px;
}
.menu_box .ul_li li.lil{
  
  height:25px;
  padding:0px;
  display: flex;
  justify-content: center;
  align-items:flex-start;
}
.menu_box .ul_li li.lil a {
   font-size: 13px;
   color:white;
  padding-top: 0px;
  padding-bottom: 0px;
 margin-left:0px;
 margin:0px;
 text-decoration: none;
}

 




#submenu_toggle {
  opacity: 1;
}



#submenu_toggle:checked~ul.submenu {
  display: block;
}


 

 

#submenu_toggle {
  display: none;
}

#submenu_toggle1:checked~ul.submenu1 {
  display: block;
}

 

 
   












#submenu_toggle1 {
  display: none;
}


#submenu_toggle2:checked~ul.submenu2 {
  display: block;
}

#submenu_toggle2 {
  display: none;
}



#submenu_toggle3:checked~ul.submenu3 {
  display: block;
}

#submenu_toggle3 {
  display: none;
}



 
 


  
 








/************************************    end Menu Burger **************************************************************/


  }

