.modal_bsk{
height:100vh;
width:100vw;
background-color: rgb(0,0,0,0.4);
position:fixed;
top:0;
left:0;
display: flex;
justify-content: center;
align-items: center;
z-index:100;
opacity: 0;
pointer-events: none;
transition: 0.5s;
}

.modal_active{
   opacity: 1;
   pointer-events: all;
    height:100vh;
width:100vw;
background-color: rgb(0,0,0,0.4);
position:fixed;
top:0;
left:0;
display: flex;
justify-content: center;
align-items: center;
z-index:100;
}

.modal_cont{
 padding:20px;
 border-radius: 5px;
 background-color: aliceblue;
 width:80%;
 height:80%;
 transform: scale(0.5);
    transition: 0.4s all;
    box-shadow: 0px 0px 3px 1px rgb(29, 31, 29);
}

.modal_active_cont{
    padding:20px;
    border-radius: 5px;
    background-color: aliceblue;
    width:80%;
    height:80%;
    transform: scale(1);
    transition: 0.4s all;
    box-shadow: 0px 0px 3px 1px rgb(29, 31, 29);
    display: flex;    
    flex-direction: column;
    align-content:center
   }



   .header_basket{
    width:100%;
    height:70px;
    
    display: flex;
    align-items: center; 
   }

   .header_basket img{
    border-radius: 50px;
    width:50px;
    height:50px;
    border:1px #db8f1d solid;
    padding:10px;
    position: absolute;
    margin-top:-25px;
   }

.ul_header{
   list-style: none;
   display: flex;
   flex-direction: row;
   align-items:flex-end;

   font-weight: 200;
   font-family: 'Montserrat';    
   width:100%;
   height:100%;
   font-size: 13px;
 justify-content:space-around
}

.ul_header li{
    height:auto;
    padding:5px;
    
}
.ul_header li.name{   
     
    text-align: center;   
}

.ul_header li.kol{   
   
    text-align: center;
}

.ul_header li.price{   
     
    text-align: center;
}

.ul_header li.priceall{ 
    
    text-align: center;
}

   .section_basket {
      height:80%;    
   }


   .footer_basket {
height:90px;
border-top:1px grey solid;
 display: flex;
 align-items:flex-end;
 justify-content: space-between;
 
   }

   .footer_basket .button_left{
   background: #3d3d3d;
    border:none;
    color:white;padding:5px;
    font-size: 13px;
     padding:10px;
     padding-left:20px;  font-family: 'Montserrat';  
     clip-path: polygon(7% 0%, 100% 0%, 100% 100%, 7% 100%, 0% 50%);
   }


   .footer_basket .button_left a{

text-decoration: none;
   }
   .footer_basket .button_right{
    background:   rgb(242, 138, 30); 
    border:none;
    color:white;
    padding:5px;
    font-size: 13px;
    font-family: 'Montserrat';  
    width:180px;
   }
  
 

   @media(max-width:900px){
     
    .ul_header{
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items:flex-end;
     
        font-weight: 200;
        font-family: 'Montserrat';    
        width:100%;
        height:100%;
        font-size: 12px;
      justify-content:space-around
     }
           .footer_basket .button_left{
           background: #3d3d3d;
            border:none;
            color:white;padding:5px;
            font-size: 12px;
             padding:10px;
             padding-left:20px;  font-family: 'Montserrat';  
             clip-path: polygon(7% 0%, 100% 0%, 100% 100%, 7% 100%, 0% 50%);
           }
           .footer_basket .button_right{
            background:   rgb(242, 138, 30); 
            border:none;
            color:white;
            padding:5px;
            font-size: 12px;
            font-family: 'Montserrat';  
            width:180px;
           }
          
           
   }
 


   
   @media(max-width:768px){
    .modal_active{
        opacity: 1;
        pointer-events: all;
         height:100vh;
     width:100vw;
     background-color: rgb(0,0,0,0.4);
     position:fixed;
     top:0;
     left:0;
     display: flex;
     justify-content: center;
     align-items: center;
     z-index:100;
     padding-top:50px;
     }
    .modal_active_cont{
        padding:20px;
        border-radius: 5px;
        background-color: aliceblue;
        width:90%;
        height:90%;
        transform: scale(1);
        transition: 0.4s all;
        box-shadow: 0px 0px 3px 1px rgb(29, 31, 29);
        display: flex;    
        flex-direction: column;
        align-content:center
       }
    .header_basket{
        width:100%;
        height:90px;
        
        display: flex;
        align-items: center; 
       }
    .header_basket img{
        border-radius: 50px;
        width:40px;
        height:40px;
        border:1px #db8f1d solid;
        padding:7px;
        position: absolute;
        margin-top:-40px;
       
       }
    .ul_header{
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items:flex-end;
     
        font-weight: 200;
        font-family: 'Montserrat';    
        width:100%;
        height:100%;
        font-size: 12px;
      justify-content:space-around;
      margin:0px;
      padding:0px;
      
     }


     .ul_header li{
        height:auto;
        padding:5px;
        
    }
           .footer_basket .button_left{
           background: #3d3d3d;
            border:none;
            color:white;padding:5px;
            font-size: 12px;
             padding:10px;
             padding-left:20px;  font-family: 'Montserrat';  
             clip-path: polygon(7% 0%, 100% 0%, 100% 100%, 7% 100%, 0% 50%);
           }
        
         


           
   }
 


   
   
   @media(max-width:560px){

   





    .modal_active_cont{
        padding:10px;
        border-radius: 5px;
        background-color: aliceblue;
        width:90%;
        height:90%;
        transform: scale(1);
        transition: 0.4s all;
        box-shadow: 0px 0px 3px 1px rgb(29, 31, 29);
        display: flex;    
        flex-direction: column;
        align-content:center
       }
    .header_basket{
        width:100%;
        height:90px;
        
        display: flex;
        align-items: center; 
       }
    .header_basket img{
        border-radius: 50px;
        width:40px;
        height:40px;
        border:1px #db8f1d solid;
        padding:7px;
        position: absolute;
        margin-top:-40px;
       
       }
    .ul_header{
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items:flex-end;
     
        font-weight: 200;
        font-family: 'Montserrat';    
        width:100%;
        height:100%;
        font-size: 12px;
      justify-content:space-around;
      margin:0px;
      padding:0px;
      
     }


     .ul_header li{
        height:auto;
        padding:5px;
        
    }


    .footer_basket {
        height:100px;
        border-top:1px grey solid;
         display: flex;
         align-items:center;
         justify-content: center;
         flex-direction: column;
         padding:15px;
         
           }
           .footer_basket .button_left{
           background: none;
            border:1px solid #3d3d3d;
            color:#3d3d3d;
            padding:5px;
            font-size: 12px;
             padding:10px;
             font-family: 'Montserrat';  
             clip-path: none;
             width:90%;
order:2;
           }
        
           .footer_basket .button_right{
            background:   rgb(242, 138, 30); 
            order:1;
            border:none;
            color:white;
            padding:10px;
            font-size: 12px;
            font-family: 'Montserrat';  
            width:90%;
            margin-bottom:5px;
           }
          


           
   }



   
   
   @media(max-width:490px){
    .modal_active_cont{
        padding:10px;
        border-radius: 5px;
        background-color: aliceblue;
        width:90%;
        height:90%;
        transform: scale(1);
        transition: 0.4s all;
        box-shadow: 0px 0px 3px 1px rgb(29, 31, 29);
        display: flex;    
        flex-direction: column;
        align-content:center
       }
    .header_basket{
        width:100%;
        height:90px;
        
        display: flex;
        align-items: center; 
       }
    .header_basket img{
        border-radius: 50px;
        width:40px;
        height:40px;
        border:1px #db8f1d solid;
        padding:7px;
        position: absolute;
        margin-top:-40px;
       
       }
    .ul_header{
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items:flex-end;
     
        font-weight: 200;
        font-family: 'Montserrat';    
        width:100%;
        height:100%;
        font-size: 12px;
      justify-content:space-around;
      margin:0px;
      padding:0px;
      
     }


     .ul_header li{
        height:auto;
        padding:5px;
        
    }


    .footer_basket {
        height:100px;
        border-top:1px grey solid;
         display: flex;
         align-items:center;
         justify-content: center;
         flex-direction: column;
         padding:10px;
         
           }
           .footer_basket .button_left{
           background: none;
            border:1px solid #3d3d3d;
            color:#3d3d3d;
            padding:7px;
            font-size: 12px;
          
             font-family: 'Montserrat';  
             clip-path: none;
             width:100%;
order:2;
           }
        
           .footer_basket .button_right{
            background:   rgb(242, 138, 30); 
            order:1;
            border:none;
            color:white;
            padding:7px;
            font-size: 12px;
            font-family: 'Montserrat';  
            width:100%;
            margin-bottom:5px;
           }
          


           
   }


   
   
   @media(max-width:410px){
     
         
    .modal_active{
        opacity: 1;
        pointer-events: all;
         height:100vh;
     width:100vw;
     background-color: rgb(0,0,0,0.4);
     position:fixed;
     top:0;
     left:0;
     display: flex;
     justify-content: center;
     align-items: center;
     z-index:100;
     padding-top:50px;
     }
.modal_cont{
    padding:10px;
    border-radius: 5px;
    background-color: aliceblue;
    width:95%;
    height:95%; 
    transform: scale(0.5);
       transition: 0.4s all;
       box-shadow: 0px 0px 3px 1px rgb(29, 31, 29);
       z-index: 101;
   }
   
   .modal_active_cont{
       padding:10px;
   
       border-radius: 5px;
       background-color: aliceblue;
       width:95%;
       height:95%;
       transform: scale(1);
       transition: 0.4s all;
       box-shadow: 0px 0px 3px 1px rgb(29, 31, 29);
       display: flex;    
       flex-direction: column;
       align-content:center;
       z-index: 101;
      }
   
            

           
   }
 