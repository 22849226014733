.section{
    width:100%;
    height:700px;
   
   /* background-color: #1d2636;
    background-image:url('/src/img/zal2.jpg');
    background-size:cover;*/
      -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
       -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                               supported by Chrome, Edge, Opera and Firefox */        
                              /* background-attachment: fixed;          
                               background-repeat: no-repeat; /* Не повторяем */
                             /*  background-size: 100%  100%; /* Размер фона */
                             /*  background-position: center; /* Позиция картинкипо центру */
}


@media (max-width: 990px) {

  .section{
    width:100%;
    height:400px;}



}


 
.section .carousel{
  width:100%;
  height:100%;
 
}


.section   .carousel-item {
 height:100%;
 
}

.section   .carousel-inner {
  height:100%;
  
 }


 @media (max-width: 560px) {

  .section{
    width:100%;
    height:300px;}



}