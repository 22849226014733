.send_container{
    width:100%;
    height:auto;
   
    background-color: #f8eee7; 
     background-color:  #f5f5f5;
   
      -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
       -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                               supported by Chrome, Edge, Opera and Firefox */                  
                               background-image: url('./../img/fons2.jpg');
 
                               background-repeat: repeat;
                               
                             
                               
                               background-size: 550px 260px;                   
}
.send_grid{
     width:100%;
    height:450px;
    margin:0 auto;
    padding:0px;
   display: flex;
   flex-direction: row;
   justify-content:space-between;
   align-items: center;
   flex-direction: row;
   background-color:  #f5f5f5f3;
    }
    


    .zakaz{
        max-width: 1200px;
      padding: 0px 0px;
      margin: 40px auto;
       height:300px;
         
  }
  
   
  
  
  .zakazchik{
  
        display: flex;
      flex-direction: row;
      -webkit-box-align: center;
      align-items: center;
      padding: 0px;
      height:300px;
       
       font-weight: 500;
   font-size: 14px;
    font-family:Montserrat;
   
  }
  



  .menu_shef{
    width:45%;
    margin-left:5%;
    padding-top:10px;
    height:100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
    
     background-image: url('./../img/menu.png');
     background-size:  cover;
     
     background-size: 100% 100%;
    }
    
    .menu_shef p{
      font-weight: bold;
      margin:5px ;
      float:left;
      color:#3d3d3d;
      text-transform:none; 
      font-family:'Oswald';
      margin-left:20px;
      margin-right:0px;
      font-size: 16px;
    }








  .shef{
    flex-basis: 50%;  
   
padding:0px;
   text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
 


  
  .shef img{
    width:150px;
    height:150px;
    float:right;
   margin-top:20px;
  }
   
  .shef p{
    font-weight: bold;
    margin:10px ;
    float:left;
    color:#3d3d3d;
    text-transform:uppercase; 
    font-family:'Oswald';
    margin-left:20px;
    margin-right:0px;
    font-size: 16px;
  }
   
  @media (max-width: 990px) {

    .send_grid{
      width:100%;
     height:350px;
     margin:0 auto;
     padding:0px;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    flex-direction: row;
    background-color:  #f5f5f5f3;
  
     }
     
 
 
     .zakaz{
         width: 100%;
       padding: 0px 0px;
       margin: 30px auto;
        height:300px;
       
   }
   
    
   
   
   .zakazchik{
    
         display: flex;
       flex-direction: row;
       -webkit-box-align: center;
       align-items: center;
       padding: 0px;
       height:300px;
        
        font-weight: 500;
    font-size: 14px;
     font-family:Montserrat;
    
   }
   
 
 
 
   .menu_shef{
     width:50%;
     margin-left:5%;
     padding-top:10px;
     height:100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
     
      background-image: url('./../img/menu.png');
      background-size:  cover;
      
      background-size: 100% 100%;
     }
     
     .menu_shef p{
       font-weight: bold;
       margin:5px ;
       float:left;
       color:#3d3d3d;
       text-transform:none; 
       font-family:'Oswald';
       margin-left:20px;
       margin-right:0px;
       font-size: 14px;
     }
 
 
 
 
 
 
 
 
   .shef{
     flex-basis: 45%;  
    
 padding:0px;
    text-align: center;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
 }
  
 
 
   
   .shef img{
     width:150px;
     height:150px;
     float:right;
    margin-top:20px;
   }
    
   .shef p{
     font-weight: bold;
     margin:10px ;
     float:left;
     color:#3d3d3d;
     text-transform:uppercase; 
     font-family:'Oswald';
     margin-left:20px;
     margin-right:0px;
     font-size: 14px;
   }


  }


  
  @media (max-width: 730px) {

    .send_grid{
      width:100%;
     height:700px;
     margin:0 auto;
     padding:0px;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    flex-direction: row;
    background-color:  #f5f5f5f3;
  
     }
     
 
 
     .zakaz{
         width: 100%;
       padding: 0px 0px;
       margin: 30px auto;
        height:600px;
        
   }
   
    
   
   
   .zakazchik{
    
         display: flex;
       flex-direction: column;
       -webkit-box-align: center;
       align-items: center;
       padding: 0px;
       height:600px;
        
        font-weight: 500;
    font-size: 14px;
     font-family:Montserrat;
    
   }
   
 
 
 
   .menu_shef{
     width:80%;
     margin:0 auto;
     padding-top:10px;
     height:100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
     
      background-image: url('./../img/menu.png');
      background-size:  cover;
      
      background-size: 100% 100%;
     }
     
     .menu_shef p{
       font-weight: bold;
       margin:5px ;
       float:left;
       color:#3d3d3d;
       text-transform:none; 
       font-family:'Oswald';
       margin-left:20px;
       margin-right:0px;
       font-size: 14px;
     }
 
 
 
 
 
 
 
 
   .shef{
    width:80%;
    margin:0 auto;
    
 padding:0px;
    text-align: center;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
 }
  
 
 
   
   .shef img{
     width:150px;
     height:150px;
     float:right;
    margin-top:20px;
   }
    
   .shef p{
     font-weight: bold;
     margin:10px ;
     float:left;
     color:#3d3d3d;
     text-transform:uppercase; 
     font-family:'Oswald';
     margin-left:20px;
     margin-right:0px;
     font-size: 14px;
   }


  }



  
  
  @media (max-width: 480px) {

    .send_grid{
      width:100%;
     height:700px;
     margin:0 auto;
     padding:0px;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    flex-direction: row;
    background-color:  #f5f5f5f3;
 
     }
     
 
 
     .zakaz{
         width: 100%;
       padding: 0px 0px;
       margin: 0px auto;
        height:600px;
       
   }
   
    
   
   
   .zakazchik{
  
         display: flex;
       flex-direction: column;
       -webkit-box-align: center;
       align-items: center;
       padding: 0px;
       height:600px;
        
        font-weight: 500;
    font-size: 14px;
     font-family:Montserrat;
    
   }
   
 
 
 
   .menu_shef{
     width:96%;
     margin:0 auto;
     padding-top:10px;
     height:100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    
      background-image: url('./../img/menu.png');
      background-size:  cover;
      
      background-size: 100% 100%;
     }
     
     .menu_shef p{
       font-weight: bold;
       margin:5px ;
       float:left;
       color:#3d3d3d;
       text-transform:none; 
       font-family:'Oswald';
       margin-left:20px;
       margin-right:0px;
       font-size: 14px;
     }
 
 
 
 
 
 
 
 
   .shef{
    width:80%;
    margin:0 auto;
    
 padding:0px;
    text-align: center;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
 }
  
 
 
   
   .shef img{
     width:150px;
     height:150px;
     float:right;
    margin-top:20px;
   }
    
   .shef p{
     font-weight: bold;
     margin:10px ;
     float:left;
     color:#3d3d3d;
     text-transform:uppercase; 
     font-family:'Oswald';
     margin-left:20px;
     margin-right:0px;
     font-size: 14px;
   }


  }