
.card_flex{
    max-width:1200px;
    margin:0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
 
    padding:10px;
    height:auto;
   grid-gap: 7px;
}


.card{
    padding: 10px;
    text-align: left;
    background-color: transparent;
    flex-basis: 230px;
    height: 320px;
   box-shadow: 0 5px 15px 0 rgba(0,0,0,0.2);
   justify-content: space-between;
     
}

.card:hover{
 box-shadow:0px 0px 2px 0px #182b11;
    
}


.card .card_img{
 
    width: 100%;
    height: 180px;
    
    padding:2px;
}

.card .card_img img{
   
    width: 100%;
    height: 100%;
    border-radius: 5px;
    
}


.card .card_img_r{
 
  width: 100%;
  height: 350px;
  
  padding:2px;
}

.card .card_img_r img{
 
  width: 100%;
  height: 100%;
  border-radius: 5px;
  
}





.card .card_title{
    width: 100%;
     
    line-height: 1.1;
    padding: 0px;
    padding-left:5px;
    margin-bottom: 0px;
    font-size: 16px;
    font-family: 'Montserrat';
}

.card .card_comment{
 
    width: 100%;
      padding: 5px;
      padding-top:0px;
    font-size: 13px;
    font-family: 'Montserrat';
    margin:0px;
    line-height: 1.1;
}
.card_footer{
    display: flex;
    flex-direction: row;
    margin-top:5px;
 
}


.card .card_footer .card_price{
width: 55%;
    
    color: #182b11;
    /* text-shadow: 1px 1px 3px rgb(16, 51, 3); */
   
    float: left;
    padding: 5px;
    font-size: 18px;
    font-family: 'Oswald';
}
.card .card_footer button{
width: 45%;
 
color: white;
color: #182b11;
text-decoration: none;
font-family: Montserrat;
font-size: 13px;
display: inline-block;
border-radius: 4px;
/* background-color: #182b11; */
border: none;
text-align: center;
transition: all 0.5s;
cursor: pointer;
border: 2px #182b11 solid;
}



button span.vb {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  
  button span.vb:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  
  button:hover span.vb {
    padding-right: 20px;
  }
  
  button:hover span.vb:after {
    opacity: 1;
    right: 0;
  }

  @media(max-width:990px){
  
    .card_flex{
      
       width:90%;
      margin:0 auto;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content:flex-start;
      padding:10px;
      height:auto;
     grid-gap: 12px;
    }
    
    
    .card{
      padding: 10px;
      text-align: left;
      background-color: transparent;
      flex-basis: 200px;
      height: 340px;
      height: auto;
     box-shadow: 0 5px 15px 0 rgba(0,0,0,0.2);
     justify-content: space-between;
     
    }
    
    .card:hover{
    box-shadow:0px 0px 2px 0px #182b11;
      
    }
    
    
    .card .card_img{
    
      width: 100%;
      height: 140px;
      
      padding:2px;
    }
    
    .card .card_img img{
    
      width: 100%;
      height: 100%;
      border-radius: 5px;
      
    }
    
    .card .card_title{
      width: 100%;
    
      padding: 5px;
      padding-top:10px;
      font-size: 14px;
      font-family: 'Montserrat';
    }
    
    .card .card_comment{
     
      width: 100%;
        padding: 5px;
        padding-top:0px;
      font-size: 12px;
      font-family: 'Montserrat';
    }
    .card_footer{
      display: flex;
      flex-direction: row;
      margin-top:30px;
    
    }
    
    
    .card .card_footer .card_price{
    width: 40%;
      
      color: #182b11;
      /* text-shadow: 1px 1px 3px rgb(16, 51, 3); */
     
      float: left;
      padding: 5px;
      font-size: 16px;
      font-family: 'Oswald';
    }
    .card .card_footer button{
    width: 60%;
    
    color: white;
    color: #182b11;
    text-decoration: none;
    font-family: Montserrat;
    font-size: 11px;
    display: inline-block;
    border-radius: 4px;
    /* background-color: #182b11; */
    border: none;
    text-align: center;
    transition: all 0.5s;
    cursor: pointer;
    border: 1px #182b11 solid;
    }
    
    
    
    button span.vb {
      cursor: pointer;
      display: inline-block;
      position: relative;
      transition: 0.5s;
    }
    
    button span.vb:after {
      content: '\00bb';
      position: absolute;
      opacity: 0;
      top: 0;
      right: -20px;
      transition: 0.5s;
    }
    
    button:hover span.vb {
      padding-right: 20px;
    }
    
    button:hover span.vb:after {
      opacity: 1;
      right: 0;
    }
    
     
     
     }
 @media(max-width:490px){
  
.card_flex{
   width:100%;
  margin:0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content:flex-start;
  padding:10px;
  height:auto;
 grid-gap: 12px;

}

 




.card{
  padding: 10px;
  text-align: left;
  background-color: transparent;
  flex-basis: 190px;
  height: auto;
 box-shadow: 0 5px 15px 0 rgba(0,0,0,0.2);
 justify-content: space-between;
 
}

.card:hover{
box-shadow:0px 0px 2px 0px #182b11;
  
}


.card .card_img{

  width: 100%;
  height: 140px;
  
  padding:2px;
}

.card .card_img img{

  width: 100%;
  height: 100%;
  border-radius: 5px;
  
}

.card .card_title{
  width: 100%;

  padding: 5px;
  padding-top:10px;
  font-size: 14px;
  font-family: 'Montserrat';
}

.card .card_comment{
 
  width: 100%;
    padding: 5px;
    padding-top:0px;
  font-size: 12px;
  font-family: 'Montserrat';
}
.card_footer{
  display: flex;
  flex-direction: row;
  margin-top:30px;

}


.card .card_footer .card_price{
width: 40%;
  
  color: #182b11;
  /* text-shadow: 1px 1px 3px rgb(16, 51, 3); */
 
  float: left;
  padding: 5px;
  font-size: 16px;
  font-family: 'Oswald';
}
.card .card_footer button{
width: 60%;

color: white;
color: #182b11;
text-decoration: none;
font-family: Montserrat;
font-size: 11px;
display: inline-block;
border-radius: 4px;
/* background-color: #182b11; */
border: none;
text-align: center;
transition: all 0.5s;
cursor: pointer;
border: 1px #182b11 solid;
}



button span.vb {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

button span.vb:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

button:hover span.vb {
  padding-right: 20px;
}

button:hover span.vb:after {
  opacity: 1;
  right: 0;
}

 
 
 }


 
 @media(max-width:415px){
  
  .card_flex{
     width:100%;
    margin:0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content:flex-start;
    padding:10px;
    height:auto;
   grid-gap: 5px;
  }
  
  
  .card{
    padding: 10px;
    text-align: left;
    background-color: transparent;
    flex-basis: 180px;
    height: auto;
   box-shadow: 0 5px 15px 0 rgba(0,0,0,0.2);
   justify-content: space-between;
     
  }
        
   }


   @media(max-width:384px){
  
    .card_flex{
       width:100%;
      margin:0 auto;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
       
      justify-content:flex-start;
      padding:5px;
      height:auto;
     grid-gap: 5px;
    }
    
    
    .card{
      padding: 5px;
      text-align: left;
      background-color: transparent;
      flex-basis: 170px;
      height: auto;
     box-shadow: 0 5px 15px 0 rgba(0,0,0,0.2);
     justify-content: space-between;
       
    }
          
     }


     @media(max-width:355px){
  
      .card_flex{
         width:100%;
        margin:0 auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        padding:5px;
        height:auto;
       grid-gap: 5px;
      }
      
      
      .card{
        padding: 10px;
        text-align: left;
        background-color: transparent;
        flex-basis: 280px;
        height: auto;
       box-shadow: 0 5px 15px 0 rgba(0,0,0,0.2);
       justify-content: space-between;
         
      }


      .card .card_img{
 
        width: 100%;
        height: 200px;
        
        padding:2px;
    }
    
    .card .card_img img{
     
        width: 100%;
        height: 100%;
        border-radius: 5px;
        
    }
    
    .card .card_title{
        width: 100%;
      
        padding: 5px;
        padding-top:10px;
        font-size: 16px;
        font-family: 'Montserrat';
    }
    
    .card .card_comment{
       
        width: 100%;
          padding: 5px;
          padding-top:0px;
        font-size: 13px;
        font-family: 'Montserrat';
    }
    .card_footer{
        display: flex;
        flex-direction: row;
        margin-top:30px;
     
    }
    
    
    .card .card_footer .card_price{
    width: 55%;
        
        color: #182b11;
        /* text-shadow: 1px 1px 3px rgb(16, 51, 3); */
       
        float: left;
        padding: 5px;
        font-size: 18px;
        font-family: 'Oswald';
    }
    .card .card_footer button{
    width: 45%;
     
    color: white;
    color: #182b11;
    text-decoration: none;
    font-family: Montserrat;
    font-size: 13px;
    display: inline-block;
    border-radius: 4px;
    /* background-color: #182b11; */
    border: none;
    text-align: center;
    transition: all 0.5s;
    cursor: pointer;
    border: 2px #182b11 solid;
    }
    
    
    
    button span.vb {
        cursor: pointer;
        display: inline-block;
        position: relative;
        transition: 0.5s;
      }
      
      button span.vb:after {
        content: '\00bb';
        position: absolute;
        opacity: 0;
        top: 0;
        right: -20px;
        transition: 0.5s;
      }
      
      button:hover span.vb {
        padding-right: 20px;
      }
      
      button:hover span.vb:after {
        opacity: 1;
        right: 0;
      }


            
       }