.footer{
  width:100%;
  height:auto;
 flex-shrink: 0;
    color: rgb(255, 255, 255);
   background: #333;
    -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
     -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                             supported by Chrome, Edge, Opera and Firefox */                  
                          
}
 
 
  
   
  .finish{
    max-width: 1200px;    
      padding: 0px 15px;
      margin: 0px auto;
      height:400px;
      display: flex;
 
  }
  
  .ff{
    display: flex;
      -webkit-box-pack: justify;
     
       flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  justify-content: space-between;   
  width:100%;  
  }

  .ff .f1{
     
      height:auto;
    
      width:auto;
   text-align: center;
  }
  
  .ff   .logotip{
    width:150px;
    height:290px;
    margin:0px;
    padding:0px;
    font-weight: 700;
    font-size: 20px;
    font-family: 'Montserrat';
    
  }
  
  .ff   .logotip b{
    color:rgb(251, 131, 39);
    text-shadow: 1px 1px 1px rgb(252, 229, 187);
  }
  .ff   .logotip a{
    color:rgb(255, 255, 255);
    text-shadow: 1px 1px 2px rgb(255, 255, 255);
  }

  .ff .f1 ul {
    list-style: none;
    display:block;
    
    margin:60px auto;
    height:auto;
    text-align: left;
   
  }
  
  
  .ff .f1 ul li{
    margin:0px;
    padding:5px;
     font-weight:400;
   font-size: 13px;
    font-family:Montserrat;
 
  }
  
  .ff .f1 ul.menu{
  padding :10px;
   user-select: none;
   font-weight: bolder; 
  }
  
  .ff .f1 ul.menu a{
    text-decoration: none;
    color:#ececec; 
    padding :0px;
    
  }
  .ff .f1 ul li b{
     
   color:  rgb(242, 138, 30);
  }
 



  .ff .f1 ul.menu a:hover{
   text-decoration:underline;
  
  }
  
   
  
  .social-container {
    margin:0 auto;
    padding: 0px;
    width:280px;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     
  }
  
  /* Add this ID to a section or something you want to change the background-color */
   
  
  /* Button Styles */
  .social-container a {
  display: flex;
  justify-content: center;
  align-items: center;
  width:170px;
  line-height: 40px;
  border: 1px solid rgba(238, 235, 235, 0.884);
  border-radius: 40px;
  color: rgba(226, 226, 226, 0.918);
  margin-top:10px;
  text-decoration: none;
  text-align:left;
   font-family:Montserrat;
   font-size:12px;  
    
  }
  
  
  .social-container a:hover  {
  border: 1px solid rgba(255,255,255,.3);
  color: rgba(255,255,255,.6);
  }
  
  .social-container  a .icons{
    font-size: 16px;
    margin-left:10px;
  }
  
  .footer .inline{
    border-top:1px #626362 solid;
    max-width:1200px;
    margin:0 auto;
    height:110px;
  }
  
  
  
  .inline a.left{
    float:left;
    font-family:Oswald;
    margin-top:20px;
     color:#7e7e7e;
     margin-left:10px;
     text-decoration: none;
  }

  .inline a.right{
    float:right;
    font-family:'Montserrat';
    margin-top:20px;
     color:#7e7e7e;
     margin-left:-30px;
     font-size: 12px;
     text-decoration: none;
     padding:5px;
  }
  


  @media(max-width:760px){
    .footer{
      width:100%;
      height:auto;
     flex-shrink: 0;
        color: rgb(255, 255, 255);
       background: #333;
        -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
         -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                 supported by Chrome, Edge, Opera and Firefox */                  
                              
    }
     
     
      
       
      .finish{
        width: 100%;    
          padding: 0px 15px;
          margin: 0px auto;
          height:auto;
          display: flex;
     
      }
      
      .ff{
        display: flex;
          -webkit-box-pack: justify;
         
           flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      justify-content: space-between;   
      width:100%;  
      padding:10px;
      }
    
      .ff .f1{
         
          height:auto;
        
          width:auto;
       text-align: center;
      }
      
      .ff   .logotip{
        width:150px;
        height:40px;
        margin:0px;
        padding:0px;
        font-weight: 700;
        font-size: 20px;
        font-family: 'Montserrat';
        position:absolute;
        right:40px;
        margin-top:-160px;
        
      }
      
      .ff   .logotip b{
        color:rgb(251, 131, 39);
        text-shadow: 1px 1px 1px rgb(252, 229, 187);
      }
      .ff   .logotip a{
        color:rgb(255, 255, 255);
        text-shadow: 1px 1px 2px rgb(255, 255, 255);
      }
    
      .ff .f1 ul {
        list-style: none;
        display:block;
       
        margin:30px auto;
        height:auto;
        text-align: left;
       
      }
      
      
      .ff .f1 ul li{
        margin:0px;
        padding:4px;
         font-weight:400;
       font-size: 13px;
        font-family:Montserrat;
     
      }
      
      .ff .f1 ul.menu{
      padding :7px;
       user-select: none;
       font-weight: bolder; 
       
       width:130px;
      }
      
      .ff .f1 ul.menu a{
        text-decoration: none;
        color:#ececec; 
        padding :0px;
        
      }
      .ff .f1 ul li b{
         
       color:  rgb(242, 138, 30);
      }
     
    
    
    
      .ff .f1 ul.menu a:hover{
       text-decoration:underline;
      
      }
      .ff .f1 ul.contact{
        
        padding:5px;
      }
       
      
      .social-container {
        margin-top:130px;
        padding: 0px;
        width:280px;
        height:100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
         
      }
      
      /* Add this ID to a section or something you want to change the background-color */
       
      
      /* Button Styles */
      .social-container a {
      display: flex;
      justify-content: center;
      align-items: center;
      width:150px;
      line-height: 30px;
      border: 1px solid rgba(238, 235, 235, 0.884);
      border-radius: 40px;
      color: rgba(226, 226, 226, 0.918);
      margin-top:10px;
      text-decoration: none;
      text-align:left;
       font-family:Montserrat;
       font-size:12px;  
        
      }
      
      
      .social-container a:hover  {
      border: 1px solid rgba(255,255,255,.3);
      color: rgba(255,255,255,.6);
      }
      
      .social-container  a .icons{
        font-size: 16px;
        margin-left:10px;
      }
      
      .footer .inline{
        border-top:1px #626362 solid;
        max-width:1200px;
        margin:0 auto;
        height:110px;
      }
      
      
      
      .inline a.left{
        float:left;
        font-family:Oswald;
        margin-top:20px;
         color:#7e7e7e;
         margin-left:10px;
         text-decoration: none;
      }
    
      .inline a.right{
        float:right;
        font-family:'Montserrat';
        margin-top:20px;
         color:#7e7e7e;
         margin-left:-30px;
         font-size: 12px;
         text-decoration: none;
         padding:5px;
      }
  }

  @media(max-width:640px){
    .footer{
      width:100%;
      height:auto;
     flex-shrink: 0;
        color: rgb(255, 255, 255);
       background: #333;
        -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
         -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                 supported by Chrome, Edge, Opera and Firefox */                  
              padding:5px;     
                        
    }
     
     
      
       
      .finish{
        width: 100%;    
          padding: 0px 15px;
          margin: 0px auto;
          height:420px;
          display: flex;
         
      }
      
      .ff{
        display: flex;
          -webkit-box-pack: justify;
         
           flex-direction: row;
    
      align-items: center;
      justify-content: space-between;   
      width:100%;  
      padding:10px; 
      }
    
      .ff .f1{
         
          height:auto;
        
          width:auto;
       text-align: center;
      }
      
      .ff   .logotip{
        width:150px;
        height:40px;
        margin:0px;
        padding:0px;
        font-weight: 700;
        font-size: 20px;
        font-family: 'Montserrat';
        position:absolute;
        right:40px;
        margin-top:-180px;
        
      }
      
      .ff   .logotip b{
        color:rgb(251, 131, 39);
        text-shadow: 1px 1px 1px rgb(252, 229, 187);
      }
      .ff   .logotip a{
        color:rgb(255, 255, 255);
        text-shadow: 1px 1px 2px rgb(255, 255, 255);
      }
    
      .ff .f1 ul {
        list-style: none;
        display:block;
       
        margin:10px auto;
        height:auto;
        text-align: left;
       
      }
      
      
      .ff .f1 ul li{
        margin:0px;
        padding:4px;
         font-weight:400;
       font-size: 13px;
        font-family:Montserrat;
     
      }
      
      .ff .f1 ul.menu{
      padding :7px;
       user-select: none;
       font-weight: bolder; 
       
       width:130px;
      }
      
      .ff .f1 ul.menu a{
        text-decoration: none;
        color:#ececec; 
        padding :0px;
        
      }
      .ff .f1 ul li b{
         
       color:  rgb(242, 138, 30);
      }
     
    
    
    
      .ff .f1 ul.menu a:hover{
       text-decoration:underline;
      
      }
      .ff .f1 ul.contact{
        margin-top:-90px;
        padding:5px;
      }
       
      
      .social-container {
        margin:0px;
        padding: 0px;
        width:160px;
        height:auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
         position:absolute;
         right:40px;
         margin-top:270px;
       
      }
      
      /* Add this ID to a section or something you want to change the background-color */
       
      
      /* Button Styles */
      .social-container a {
      display: flex;
      justify-content: center;
      align-items: center;
      width:150px;
      line-height: 30px;
      border: 1px solid rgba(238, 235, 235, 0.884);
      border-radius: 40px;
      color: rgba(226, 226, 226, 0.918);
      margin-top:10px;
      text-decoration: none;
      text-align:left;
       font-family:Montserrat;
       font-size:12px;  
        
      }
      
      
      .social-container a:hover  {
      border: 1px solid rgba(255,255,255,.3);
      color: rgba(255,255,255,.6);
      }
      
      .social-container  a .icons{
        font-size: 16px;
        margin-left:10px;
      }
      
      .footer .inline{
        border-top:1px #626362 solid;
        max-width:1200px;
        margin:0 auto;
        height:110px;
      }
      
      
      
      .inline a.left{
        float:left;
        font-family:Oswald;
        margin-top:20px;
         color:#7e7e7e;
         margin-left:40px;
         text-decoration: none;
         font-size: 14px;
      }
    
      .inline a.right{
        float:right;
        font-family:'Montserrat';
        margin-top:20px;
         color:#7e7e7e;
         margin-left:-40px;
         font-size: 11px;
         text-decoration: none;
         padding:5px;
      }
  }

  
  @media(max-width:424px){
    .footer{
      width:100%;
      height:auto;
     flex-shrink: 0;
        color: rgb(255, 255, 255);
       background: #333;
        -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
         -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                 supported by Chrome, Edge, Opera and Firefox */                  
              padding:5px;     
                        
    }
     
     
      
       
      .finish{
        width: 100%;    
          padding: 0px 15px;
          margin: 0px auto;
          height:420px;
          display: flex;
         
      }
      
      .ff{
        display: flex;
          -webkit-box-pack: justify;
         
           flex-direction: row;
    
      align-items: center;
      justify-content: space-between;   
      width:100%;  
      padding:10px; 
      }
    
      .ff .f1{
         
          height:auto;
        
          width:auto;
       text-align: center;
      }
      
      .ff   .logotip{
        width:150px;
        height:40px;
        margin:0px;
        padding:0px;
        font-weight: 700;
        font-size: 20px;
        font-family: 'Montserrat';
        position:absolute;
        right:40px;
        margin-top:-180px;
        
      }
      
      .ff   .logotip b{
        color:rgb(251, 131, 39);
        text-shadow: 1px 1px 1px rgb(252, 229, 187);
      }
      .ff   .logotip a{
        color:rgb(255, 255, 255);
        text-shadow: 1px 1px 2px rgb(255, 255, 255);
      }
    
      .ff .f1 ul {
        list-style: none;
        display:block;
       
        margin:10px auto;
        height:auto;
        text-align: left;
       
      }
      
      
      .ff .f1 ul li{
        margin:0px;
        padding:4px;
         font-weight:400;
       font-size: 12px;
        font-family:Montserrat;
     
      }
      
      .ff .f1 ul.menu{
      padding :7px;
       user-select: none;
       font-weight: bolder; 
       
       width:130px;
      }
      
      .ff .f1 ul.menu a{
        text-decoration: none;
        color:#ececec; 
        padding :0px;
        
      }
      .ff .f1 ul li b{
         
       color:  rgb(242, 138, 30);
      }
     
    
    
    
      .ff .f1 ul.menu a:hover{
       text-decoration:underline;
      
      }
      .ff .f1 ul.contact{
        margin-top:-40px;
        padding:5px;
      }
       
      
      .social-container {
        margin:0px;
        padding: 0px;
        width:120px;
        height:auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
         position:absolute;
         right:40px;
         margin-top:310px;
       
      }
      
      /* Add this ID to a section or something you want to change the background-color */
       
      
      /* Button Styles */
      .social-container a {
      display: flex;
      justify-content:flex-end;
      align-items: center;
      width:100px;
      line-height: 20px;
      border: 1px solid rgba(238, 235, 235, 0.884);
      border-radius: 40px;
      color: rgba(226, 226, 226, 0.918);
      margin-top:5px;
      text-decoration: none;
      text-align:left;
       font-family:Montserrat;
       font-size:10px;  
      padding-left:10px;
      }
      
      
      .social-container a:hover  {
      border: 1px solid rgba(255,255,255,.3);
      color: rgba(255,255,255,.6);
      }
      
      .social-container  a .icons{
        font-size: 12px;
        margin-left:10px;
        margin-right:10px;
      }
      
      .footer .inline{
        border-top:1px #626362 solid;
        max-width:1200px;
        margin:0 auto;
        height:110px;
      }
      
      
      
      .inline a.left{
        float:left;
        font-family:Oswald;
        margin-top:23px;
         color:#7e7e7e;
         margin-left:30px;
         text-decoration: none;
         font-size: 12px;
      }
    
      .inline a.right{
        float:right;
        font-family:'Montserrat';
        margin-top:20px;
         color:#7e7e7e;
         margin-left:-30px;
         font-size: 11px;
         text-decoration: none;
         padding:5px;
      }
  }