

.spiner{
   
  margin :300px auto;
}
  


.section_one{

    width:100%;
    height:auto;
 
    padding:0px;
}
  


    /********************************* END 1170 ********************************************************************************************************/

                          .emerge {
                            position: fixed;
                            padding:5px;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            background: rgba(31, 54, 68, 0.829);
                            /* цвет фона */
                            z-index: 9999999;
                            opacity: 0;
                            -webkit-transition: opacity 400ms ease-in;
                            -moz-transition: opacity 400ms ease-in;
                            transition: opacity 400ms ease-in;
                            pointer-events: none;
                             display: flex;
                             justify-content: center;
                             align-items: center;
                            }
                          
                            /* при отображении модального окно */
                            .emerge:target {
                            opacity: 1;
                            pointer-events: auto;
                            overflow-y: auto;
                           
                            }
                          
                            /* ширина модального окна и его отступы от экрана */
                            .vsplevsu_anemagen {
                            position: relative;
                            width: auto;
                            margin: 0px auto;
                          
                            }



                          
                            @media (min-width: 576px) {
                            .vsplevsu_anemagen {
                            max-width: 500px;
                            margin: 30px auto;
                            }
                            }
                          
    
                            .emergena_konsuksua {
                            position: relative;
                            display: -webkit-box;
                            display: -webkit-flex;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-orient: vertical;
                            -webkit-box-direction: normal;
                            -webkit-flex-direction: column;
                            -ms-flex-direction: column;
                            flex-direction: column;
                            background-color: #ffffff;
                            -webkit-background-clip: padding-box;
                            background-clip: padding-box;
                            border: 1px solid rgba(0, 0, 0, .2);
                            border-radius: 5px;
                            outline: 0;
                            margin-top:5px;
                            height:auto;
                            }

    /*****************************************************  кори курсӣ *********************************************/
                            .emerge_kori_kursi {
                              position: fixed;
                              padding:5px;
                              top: 0;
                              right: 0;
                              bottom: 0;
                              left: 0;
                              background: rgba(31, 54, 68, 0.829);
                              /* цвет фона */
                              z-index: 9999999;
                              opacity: 0;
                              -webkit-transition: opacity 400ms ease-in;
                              -moz-transition: opacity 400ms ease-in;
                              transition: opacity 400ms ease-in;
                              pointer-events: none;
                               display: flex;
                             
                                
                              }
                            
                              /* при отображении модального окно */
                              .emerge_kori_kursi:target {
                              opacity: 1;
                              pointer-events: auto;
                              overflow-y: auto;
                             
                              }
 

                            .emergena_kori_kursi {
                              position: relative;
                              display: -webkit-box;
                              display: -webkit-flex;
                              display: -ms-flexbox;
                              display: flex;
                              -webkit-box-orient: vertical;
                              -webkit-box-direction: normal;
                              -webkit-flex-direction: column;
                              -ms-flex-direction: column;
                              flex-direction: column;
                              background-color: #ffffff;
                              -webkit-background-clip: padding-box;
                              background-clip: padding-box;
                              border: 1px solid rgba(0, 0, 0, .2);
                              border-radius: 5px;
                              outline: 0;
                             width: 50%;
                              height:auto;
                              margin: 100px auto;
                              }




                              @media (min-width: 990px) {
                                .emergena_konsuksua {
                                  position: relative;
                                  display: -webkit-box;
                                  display: -webkit-flex;
                                  display: -ms-flexbox;
                                  display: flex;
                                  -webkit-box-orient: vertical;
                                  -webkit-box-direction: normal;
                                  -webkit-flex-direction: column;
                                  -ms-flex-direction: column;
                                  flex-direction: column;
                                  background-color: #ffffff;
                                  -webkit-background-clip: padding-box;
                                  background-clip: padding-box;
                                  border: 1px solid rgba(0, 0, 0, .2);
                                  border-radius: 5px;
                                  outline: 0;
                                  margin-top:5px;
                                  height:auto;
                                  }
      
      
                                  .emerge_kori_kursi {
                                    position: fixed;
                                    padding:5px;
                                    top: 0;
                                    right: 0;
                                    bottom: 0;
                                    left: 0;
                                    background: rgba(31, 54, 68, 0.829);
                                    /* цвет фона */
                                    z-index: 9999999;
                                    opacity: 0;
                                    -webkit-transition: opacity 400ms ease-in;
                                    -moz-transition: opacity 400ms ease-in;
                                    transition: opacity 400ms ease-in;
                                    pointer-events: none;
                                     display: flex;
                                     justify-content: center;
                                     align-items: center;
                                    }
                                  
                                    /* при отображении модального окно */
                                    .emerge_kori_kursi:target {
                                    opacity: 1;
                                    pointer-events: auto;
                                    overflow-y: auto;
                                   
                                    }
      
      
      
      .vsplevsu_kori_kursi {
                                  position: relative;
                                  width: 50%;
                                  margin: 0px auto;
                                  
                                
                                  }
      
                                  .emergena_kori_kursi {
                                    position: relative;
                                    display: -webkit-box;
                                    display: -webkit-flex;
                                    display: -ms-flexbox;
                                    display: flex;
                                    -webkit-box-orient: vertical;
                                    -webkit-box-direction: normal;
                                    -webkit-flex-direction: column;
                                    -ms-flex-direction: column;
                                    flex-direction: column;
                                    background-color: #ffffff;
                                    -webkit-background-clip: padding-box;
                                    background-clip: padding-box;
                                    border: 1px solid rgba(0, 0, 0, .2);
                                    border-radius: 5px;
                                    outline: 0;
                                    margin-top:400px;
                                    height:auto;
                                    }



                              }

                            .emergena_tiobe {
                              position: relative;
                              display: -webkit-box;
                              display: -webkit-flex;
                              display: -ms-flexbox;
                              display: flex;
                              -webkit-box-orient: vertical;
                              -webkit-box-direction: normal;
                              -webkit-flex-direction: column;
                              -ms-flex-direction: column;
                              flex-direction: column;
                              background-color: #ffffff;
                              -webkit-background-clip: padding-box;
                              background-clip: padding-box;
                              border: 1px solid rgba(0, 0, 0, .2);
                              border-radius: 5px;
                              outline: 0;
                              margin-top:5px;
                              padding:10px;
                              }



                          
                            @media (min-width: 768px) {
                            .emergena_konsuksua {
                            -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
                            box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
                            }
                            }
                          
                            /* свойства для заголовка модального окна */
                            .kamages_designes {
                            display: -webkit-box;
                            display: -webkit-flex;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -webkit-align-items: center;
                            -ms-flex-align: center;
                            align-items: center;
                            -webkit-box-pack: justify;
                            -webkit-justify-content: space-between;
                            -ms-flex-pack: justify;
                            justify-content: space-between;
                            padding: 20px;
                            border-bottom: 1px solid #a4b2b9;
                            }
                          
                            .emergenua {
                            margin-top: 0;
                            margin-bottom: 0;
                            line-height: 1.5;
                            font-size: 1.1rem;
                            font-weight: 500;
                            padding-right:10px;
                            }
                          
                            /* свойства для кнопки "Закрыть" */
                            .overcas {
                            float: right;
                           margin-top:-2px;
                            font-size: 30px;
                            
                            line-height: 0;
                            color: #151515;
                            text-shadow: 0 1px 0 #fbfafa;
                            opacity: .5;
                            text-decoration: none;
                            }
                          
                            /* свойства для кнопки "Закрыть" */
                          .overcas:focus, .overcas:hover {
                            color: #1f1d1d;
                            text-decoration: none;
                            cursor: pointer;
                            opacity: .80;
                            text-shadow: 0 1px 0 #e0e0e0;
                          }
                          
                            /* модальное окно Семининархо*/
                            .opisaniye_okoshke {
                            position: relative;
                            -webkit-box-flex: 1;
                            -webkit-flex: 1 1 auto;
                            -ms-flex: 1 1 auto;
                            flex: 1 1 auto;
                            padding: 14px;
                            overflow: auto;
                            height:auto;
                          }



                          .op_tiobe {
                            position: relative;
                            -webkit-box-flex: 1;
                            -webkit-flex: 1 1 auto;
                            -ms-flex: 1 1 auto;
                            flex: 1 1 auto;
                            padding: 14px;
                            overflow: auto;
                            height:auto;
                          }
                          
                          .opisaniye_okoshke table {
                            color:#0f0f0f;
                             padding:5px;
                             width:100%;
                             height:100%;
                             font-size: 12px;
                          }
                          
                          .opisaniye_okoshke table tr td {
                            border:1px #0f0f0f solid;
                             padding:3px;
                             height:10px;
                          }
                           


                          


