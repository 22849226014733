

.slider{
     width:100%;
    
    height:auto;
 
    background-color: #f8eee7;
    background-color: #f4e7d8; 
    background-color: #f5f5f5; 
    background-image: url('./../img/fons2.jpg');
 
    background-repeat: repeat;
  
    
    background-size: 550px 260px;    
}

.sl{
  width:100%;
  height:100%;
    background-color:  #f5f5f5f3;
}
.cont_slider{
    max-width:1200px;
    margin:0 auto;
    height:400px; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;  
     
    }

 


 





.text_slider{
  
   width:50%;
padding:20px;
   text-align: center;
     
}


.text_slider .kak{
 width:100%;
 height:320px;
 
padding-top:90px;
background-image: url('./../img/zak.png');
 background-repeat:no-repeat;
 background-position: center;
 background-size: contain; 



}


 /*
.text_slider .kak::before{
  content: "";
  
    position: absolute;
    width: 190px;
  height: 100px;
margin-left:-174px;
  background-image: url('../img/zakladka.png');
background-repeat:no-repeat;
  background-position: center;
  background-size: contain;
  background-size: 180px 70px;
   margin-top:-30px;
    
} */

 


.text_slider .kak a{
    font-weight: 900;
font-size: 20px;
 font-family:'Oswald';
margin-left:50px;
  color:#3d3d3d;
  
}


.text_slider .kak  img.korzina{
 width: 70px;
 height:60px;
 position:absolute;
 margin-top:-20px;
 margin-left:15px;
}

.text_slider .kak  img.car{
 width: 90px;
 height:60px;
 position:absolute;
 margin-top:-10px;
 margin-left:150px;
}

.text_slider .kak p span{
    font-weight: 600;
font-size: 18px;
 font-family:'Oswald';
  color:#3d3d3d;
  text-shadow: none;
  z-index: 20000;
}




.text_slider .kak p{
    font-weight: 600;
font-size: 16px;
 font-family:'Montserrat';
  color:rgb(255, 255, 255); 
  text-shadow: 1px 1px 2px rgb(22, 22, 22);
 margin:20px;
   z-index: 20000;
}

.cont_slider .carousel{
height:320px;
 width:50%;
 padding:10px;
margin:0 auto;
 float:right;
}


.cont_slider .carousel-inner {
    position: relative;
    padding-right:5px;
    padding-left:5px;
    height:310px;
    overflow: hidden;
     
}


.cont_slider.carousel-item {
    position: relative;
    display: none;
    float: left;
    height:310px; 
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: 2s;
    transition: transform .6s ease-in-out;}

    .cont_slider .carousel-cell {
    transition: all 1s;
}

@media (max-width: 990px) {

  
   .slider{
  width:100%;
 
 height:auto;

 background-color: #f8eee7;
 background-color: #f4e7d8; 
 background-color: #f5f5f5; 
 background-image: url('./../img/fons2.jpg');

 background-repeat: repeat;

    
}

  .sl{
width:100%;
height:100%;
 background-color:  #f5f5f5f3;
}
  .cont_slider{
  width:100%;
 margin:0 auto;
 height:300px; 
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;  
  
 }



.text_slider{
  width:50%;
padding:0px;
text-align: center;
  
}


.text_slider .kak{
width:100%;
height:260px;
 padding:0px;
padding-top:85px;
/* background-image:url(/img/7.png);
background-repeat:no-repeat;
background-position: center;
background-size: contain;*/



}

.text_slider .kak a{
 font-weight: 900;
font-size: 18px;
font-family:'Oswald';
margin-left:50px;
color:#3d3d3d;

}


.text_slider .kak  img.korzina{
width: 70px;
height:60px;
position:absolute;
margin-top:-20px;
margin-left:15px;
}

.text_slider .kak  img.car{
width: 90px;
height:60px;
position:absolute;
margin-top:-10px;
margin-left:150px;
}

.text_slider .kak p span{
 font-weight: 600;
font-size: 18px;
font-family:'Oswald';
color:#3d3d3d;
text-shadow: none;
z-index: 20000;
}




.text_slider .kak p{
 font-weight: 600;
font-size: 16px;
font-family:'Montserrat';
color:rgb(255, 255, 255); 
text-shadow: 1px 1px 2px rgb(22, 22, 22);
margin:20px;
z-index: 99;
}
 .cont_slider .carousel{
height:260px;
width:50%;
padding:5px;
margin:0 auto;
float:right;   
}

.cont_slider .carousel-inner {
 position: relative;
 padding-right:5px;
 padding-left:5px;
 height:250px;
 overflow: hidden;
  
}

  .carousel-item {
 position: relative;
 display: none;
 float: left;
 height:250px; 
 width: 100%;
 margin-right: -100%;
 backface-visibility: hidden;
 transition: 2s;
 transition: transform .6s ease-in-out;}


 .carousel-cell {
 transition: all 1s;
}
}



@media (max-width: 730px) {

     .slider{
    width:100%;
   
   height:auto;
  
   background-color: #f8eee7;
   background-color: #f4e7d8; 
   background-color: #f5f5f5; 
   background-image: url('./../img/fons2.jpg');
  
   background-repeat: repeat;
  
   
    
  }
  
  .sl{
  width:100%;
  height:100%;
   background-color:  #f5f5f5f3;
  }
  .cont_slider{
    width:100%;
   margin:0 auto;
   height:600px; 
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;  
 
   }
  
  
  
  
  
  
    
  
  
  
  
  .text_slider{
    
  width:100%;
  padding:0px;
  text-align: center;
    
  }
  
  
  .text_slider .kak{
  width:100%;
  height:300px;
   padding:0px;
  padding-top:85px;
  /* background-image:url(/img/7.png);
  background-repeat:no-repeat;
  background-position: center;
  background-size: contain;*/
  
  
  
  }
  
  .text_slider .kak a{
   font-weight: 900;
  font-size: 18px;
  font-family:'Oswald';
  margin-left:50px;
  color:#3d3d3d;
  
  }
  
  
  .text_slider .kak  img.korzina{
  width: 70px;
  height:60px;
  position:absolute;
  margin-top:-20px;
  margin-left:15px;
  }
  
  .text_slider .kak  img.car{
  width: 90px;
  height:60px;
  position:absolute;
  margin-top:-10px;
  margin-left:150px;
  }
  
  .text_slider .kak p span{
   font-weight: 600;
  font-size: 18px;
  font-family:'Oswald';
  color:#3d3d3d;
  text-shadow: none;
  z-index: 99;
  }
  
  
  
  
  .text_slider .kak p{
   font-weight: 600;
  font-size: 16px;
  font-family:'Montserrat';
  color:rgb(255, 255, 255); 
  text-shadow: 1px 1px 2px rgb(22, 22, 22);
  margin:20px;
  z-index: 99;
  }
  .cont_slider   .carousel{
  height:300px;
  width:90%;
  padding:20px;
  margin:0 auto;
  float:right;  
   
  }
  
  .cont_slider .carousel-inner {
   position: relative;
   padding-right:5px;
   padding-left:5px;
   height:260px;
   overflow: hidden;
    
  }
  
     .carousel-item {
   position: relative;
   display: none;
   float: left;
   height:260px; 
   width: 100%;
   margin-right: -100%;
   backface-visibility: hidden;
   transition: 2s;
   transition: transform .6s ease-in-out;}
  
  .carousel-cell {
   transition: all 1s;
  }
  }




  

@media (max-width: 560px) {

  .slider{
 width:100%;

height:auto;

background-color: #f8eee7;
background-color: #f4e7d8; 
background-color: #f5f5f5; 
background-image: url('./../img/fons2.jpg');

background-repeat: repeat;


 
}

.sl{
width:100%;
height:100%;
background-color:  #f5f5f5f3;
}
.cont_slider{
 width:100%;
margin:0 auto;
height:600px; 
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;  

}






 




.text_slider{
 
width:90%;
padding:0px;
text-align: center;
 
}


.text_slider .kak{
width:100%;
height:300px;
padding:0px;
padding-top:85px;
/* background-image:url(/img/7.png);
background-repeat:no-repeat;
background-position: center;
background-size: contain;*/



}

.text_slider .kak a{
font-weight: 900;
font-size: 18px;
font-family:'Oswald';
margin-left:50px;
color:#3d3d3d;

}


.text_slider .kak  img.korzina{
width: 70px;
height:60px;
position:absolute;
margin-top:-20px;
margin-left:15px;
}

.text_slider .kak  img.car{
width: 90px;
height:60px;
position:absolute;
margin-top:-10px;
margin-left:150px;
}

.text_slider .kak p span{
font-weight: 600;
font-size: 18px;
font-family:'Oswald';
color:#3d3d3d;
text-shadow: none;
z-index: 99;
}




.text_slider .kak p{
font-weight: 600;
font-size: 16px;
font-family:'Montserrat';
color:rgb(255, 255, 255); 
text-shadow: 1px 1px 2px rgb(22, 22, 22);
margin:20px;
z-index: 99;
}
.cont_slider   .carousel{
height:300px;
width:90%;
padding:5px;
margin:0 auto;
float:right;  
 
}

.cont_slider .carousel-inner {
position: relative;
padding-right:5px;
padding-left:5px;
height:290px;
overflow: hidden;
 
}

  .carousel-item {
position: relative;
display: none;
float: left;
height:290px; 
width: 100%;
margin-right: -100%;
backface-visibility: hidden;
transition: 2s;
transition: transform .6s ease-in-out;}

.carousel-cell {
transition: all 1s;
}
}





@media (max-width: 480px) {

  .slider{
 width:100%;

height:auto;

background-color: #f8eee7;
background-color: #f4e7d8; 
background-color: #f5f5f5; 
background-image: url('./../img/fons2.jpg');

background-repeat: repeat;


 
}

.sl{
width:100%;
height:100%;
background-color:  #f5f5f5f3;
}
.cont_slider{
 width:100%;
margin:0 auto;
height:500px; 
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;  

}






 




.text_slider{
 
width:95%;
padding:0px;
text-align: center;
 
}


.text_slider .kak{
width:100%;
height:250px;
padding:0px;
padding-top:65px;
/* background-image:url(/img/7.png);
background-repeat:no-repeat;
background-position: center;
background-size: contain;*/



}

.text_slider .kak a{
font-weight: 900;
font-size: 18px;
font-family:'Oswald';
margin-left:50px;
color:#3d3d3d;

}


.text_slider .kak  img.korzina{
width: 70px;
height:60px;
position:absolute;
margin-top:-20px;
margin-left:15px;
}

.text_slider .kak  img.car{
width: 90px;
height:60px;
position:absolute;
margin-top:-10px;
margin-left:150px;
}

.text_slider .kak p span{
font-weight: 600;
font-size: 18px;
font-family:'Oswald';
color:#3d3d3d;
text-shadow: none;
z-index: 99;
}




.text_slider .kak p{
font-weight: 600;
font-size: 16px;
font-family:'Montserrat';
color:rgb(255, 255, 255); 
text-shadow: 1px 1px 2px rgb(22, 22, 22);
margin:20px;
z-index: 99;
}
.cont_slider   .carousel{
height:250px;
width:100%;
padding:5px;
margin:0 auto;
float:right;  
 
}

.cont_slider .carousel-inner {
position: relative;
padding-right:5px;
padding-left:5px;
height:240px;
overflow: hidden;
 
}

  .carousel-item {
position: relative;
display: none;
float: left;
height:240px; 
width: 100%;
margin-right: -100%;
backface-visibility: hidden;
transition: 2s;
transition: transform .6s ease-in-out;}

.carousel-cell {
transition: all 1s;
}
}